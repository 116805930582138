<template>
  <div class="h-min-content">
    <div class="p-6 md:p-10 bg-white rounded-2xl shadow-lg mx-4">
      <h2 class="text-left text-3xl text-blue-dark py-2"><b>Registro</b></h2>
      <p class="text-left text-sm text-gray-400 mb-8">
        Ingrese todos los datos de la persona que tiene la inconformidad
      </p>
      <form
        @submit.prevent="signUp"
        class="w-full max-w-md mb-4 mx-auto px-0 py-0"
      >
        <h2 class="mb-3 text-xl text-left text-blue">
          <b>Datos de contacto</b>
        </h2>
        <div class="w-full">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="form.person_type"
          />
        </div>
        <CustomerNaturalForm
          ref="form"
          v-if="form.person_type === 1"
          :disabled="disabled"
          @setForm="setForm"
        />
        <CustomerLegalForm
          ref="form"
          v-if="form.person_type === 2"
          :fileList="files"
          :disabled="disabled"
          @setForm="setForm"
          @setFiles="setFormFiles"
        />
        <div v-if="form.person_type">
          <div class="w-full">
            <BaseRadio
              ref="personalData"
              name="personalData"
              id="personalData"
              label="¿Autorizo el tratamiento de datos personales?"
              :radioOptions="optionsYN"
              v-model="form.personal_data"
              :required="true"
            />
            <div />
            <BaseRadio
              ref="sensitiveData"
              name="sensitiveData"
              id="sensitiveData"
              label="¿Autorizo el tratamiento de datos sensibles?"
              :radioOptions="optionsYN"
              v-model="form.sensitive_data"
              :required="true"
            />
          </div>
          <div v-if="feedback">
            <p class="mt-5 text-xs text-center text-red-500">{{ feedback }}</p>
          </div>
          <div class="w-full my-8 mx-auto">
            <p class="my-3 text-xs text-center">
              Consulte la
              <a
                class="text-blue"
                href="https://www.superfinanciera.gov.co/publicaciones/10106783/nuestra-entidadacerca-de-la-sfcpoliticas-de-la-entidadpoliticas-web-10106783/"
                target="_blank"
                rel="noopener"
              >
                <b>
                  política de tratamiento de datos personales y sensibles de la SFC
                </b>
              </a>
            </p>
            <a-button
              type="primary"
              html-type="submit"
              :block="true"
              shape="round"
              :loading="loading"
            >
              REGISTRAR USUARIO
            </a-button>
          </div>
        </div>
      </form>
    </div>

     <!-- MODAL INFORMATION FOR ADMIN USERS WHEN USER WITHOUT EMAIL IS REGISTERED -->
     <Modal v-if="openModalInformation">
      <div class="w-10/12 m-auto text-sm">
        <p class="text-gray-500">
          Se acaba de crear un nuevo usuario sin correo electrónico, por favor tome nota de las credenciales.
          <br/>
          <br/>
          Nombre de usuario: <b>{{ this.username }} </b>
          <br/>
          Contraseña: <b>{{ this.password }} </b>
        </p>
      </div>
      <div class="w-4/5 m-auto mt-5 flex flex-col gap-4 md:flex-row md:justify-center md:items-center md:gap-4">
        <a-button @click.native="hideModalInfo" type="primary" shape="round" :block="true">
          ENTENDIDO
        </a-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import CustomerNaturalForm from "@/components/Dashboard/Customers/CustomerNaturalForm";
import CustomerLegalForm from "@/components/Dashboard/Customers/CustomerLegalForm";
import capitalising from "@/utils/checkForms";

export default {
  components: {
    CustomerLegalForm,
    CustomerNaturalForm,
  },
  data() {
    return {
      customer_form: {},
      form: {
        person_type: null,
        personal_data: 2,
        sensitive_data: 2,
      },
      files: [],
      feedback: "",
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      loading: false,
      disabled: false,
      openModalInformation: false,
      username: "",
      password: "",
    };
  },
  mounted() {},
  methods: {
    async signUp() {
      if (!this.checkForm()) {
        return;
      }

      let form = {
        person_type: this.person_type,
        ...this.form,
        ...this.customer_form,
        email: this.customer_form.email
          ? this.customer_form.email.toLowerCase()
          : undefined,
        email_confirmation: this.customer_form.email_confirmation
          ? this.customer_form.email_confirmation.toLowerCase()
          : undefined,
        first_name: capitalising(this.customer_form.first_name),
        last_name: capitalising(this.customer_form.last_name),
      };
      // Aditional modification
      if (form.person_type === 2) {
        form.business_name = capitalising(form.business_name);
      }
      if (form.hasEmail == 2) {
        delete form.email;
        delete form.email_confirmation;
      }
      delete form.identification_number_confirmation;
      if(!form.birth_date) delete form.birth_date
      this.loading = true;
      let { data, error } = await this.$api.signup(form, this.files);
      this.loading = false;

      if (error) {
        if (error.errors) {
          let err = "";
          for (let i in error.errors) {
            err += error.errors[i] + " ";
          }
          this.feedback = err;
        } else {
          this.feedback = "Hubo un error al registrar al usuario.";
        }
      }

      if (data) {
        if (data.email) {
          const { identification_type, identification_number } = form;
          this.$router.push({
            name: "ComplaintRegistration",
            query: { identification_type, identification_number },
          });
        } else {
          this.openModalInformation = true;
          this.openModalInformationCredentials(data, form)
        }
      }
    },
    setForm(form) {
      this.customer_form = { ...form };
    },
    setFormFiles(files) {
      this.files = files;
    },
    checkForm() {
      this.feedback = "";
      let valid = false;

      let refs = { ...this.$refs.form.$refs };

      for (let ref in refs) {
        if (
          (refs[ref] && refs[ref].required && refs[ref].value === "") ||
          refs[ref]?.invalid
        ) {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      let regex = /[^a-zñÑ\s]/i;
      let regexNIT = /[0-9]{7,}\-[0-9]{1}/;
      if (
        regex.test(this.customer_form.first_name + "") ||
        regex.test(this.customer_form.last_name + "")
      ) {
        this.feedback =
          "En los campos nombres y apellidos no se permiten carácteres especiales ni acentos";
        return valid;
      }
      if (this.$validator.isEmpty(this.customer_form.first_name + "")) {
        this.feedback = "Introduzca el nombre completo del cliente";
        return valid;
      }
      if (this.$validator.isEmpty(this.customer_form.last_name + "")) {
        this.feedback = "Introduzca los apellidos del cliente";
        return valid;
      }
      if (regex.test(this.customer_form.business_name + "")) {
        this.feedback =
          "En el campo razón social no se permite caracteres especiales ni acentos";
        return valid;
      }

      if (this.$validator.isEmpty(this.customer_form.business_name + "")) {
        this.feedback = "Introduzca la razón social";
        return valid;
      }
      if (
        this.$validator.isEmpty(this.customer_form.identification_number + "")
      ) {
        this.feedback = "Introduzca un número de identificación válido";
        return valid;
      }
      if (
        this.$validator.isEmpty(this.customer_form.identification_type + "")
      ) {
        this.feedback = "Seleccione un tipo de identificación";
        return valid;
      }
      // Validación para la cédula de ciudadanía
      if (
        this.customer_form.identification_type == 1 &&
        !this.$validator.isNumeric(
          this.customer_form.identification_number + ""
        )
      ) {
        this.feedback = "Ingrese un número válido de documento";
        return valid;
      }
      // Validación para NIT
      if (
        this.customer_form.identification_type == 3 &&
        !regexNIT.test(this.customer_form.identification_number + "")
      ) {
        this.feedback = "Ingrese un NIT válido";
        return valid;
      }
      if ((this.customer_form.identification_number + "").length > 15) {
        this.feedback =
          "La longitud máxima del número de identificación debe ser 15.";
        return valid;
      }
      if (
        this.customer_form.identification_number &&
        !this.$validator.equals(
          this.customer_form.identification_number,
          this.customer_form.identification_number_confirmation
        )
      ) {
        this.feedback = "Los números de identificación no coinciden";
        return valid;
      }
      if (
        this.customer_form.identification_number &&
        !this.$validator.equals(
          this.customer_form.identification_number,
          this.customer_form.identification_number_confirmation
        )
      ) {
        this.feedback = "Los números de identificación no coinciden";
        return valid;
      }
      if (this.form.phone && this.form.phone.toString().length < 7) {
        this.feedback = "Ingrese un número de teléfono válido.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length > 15) {
        this.feedback = "El número de teléfono no puede ser mayor a 15.";
        return valid;
      }
      if (this.customer_form.birth_date) {
        if (
          this.form.person_type == 1 &&
          !this.$validator.isDate(this.customer_form.birth_date)
        ) {
          this.feedback = "Introduzca una fecha de nacimiento válida";
          return valid;
        }
      }
      if (this.customer_form.hasEmail == 1) {
        if (!this.$validator.isEmail(this.customer_form.email)) {
          this.feedback = "Introduzca un correo válido";
          return valid;
        }
        if (
          !this.$validator.equals(
            this.customer_form.email,
            this.customer_form.email_confirmation
          )
        ) {
          this.feedback = "El correo electrónico no coincide";
          return valid;
        }
      } else {
        // Validaciones para usuario sin correo
        if (this.$validator.isEmpty(this.customer_form.address + "")) {
          this.feedback = "La dirección es obligatoria";
          return valid;
        }
        if (this.$validator.isEmpty(this.customer_form.departamento_cod + "")) {
          this.feedback = "el departamento es obligatorio";
          return valid;
        }
        if (this.$validator.isEmpty(this.customer_form.municipio_cod + "")) {
          this.feedback = "El municipio es obligatorio";
          return valid;
        }
      }
      if (this.form.personal_data === 2) {
        this.feedback = "Debe aceptar el tratamiento de datos personales";
        return valid;
      }
      if (this.form.sensitive_data === 2) {
        this.feedback = "Debe aceptar el tratamiento de datos sensibles";
        return valid;
      }
      if (this.form.person_type == 2 && this.files.length == 0) {
        this.feedback =
          "Debe adjuntar el poder que lo identifique como representante de la empresa";
        return valid;
      }

      valid = true;
      this.feedback = "";
      return valid;
    },
    openModalInformationCredentials(data, form){
      this.username = data.username;
      this.password = form.identification_number;
      this.openModalInformation = true;
    },
    hideModalInfo() {
      this.openModalInformation = false;
      let form = {
        ...this.form,
        ...this.customer_form,
      };
      const { identification_type, identification_number } = form;
      this.$router.push({
          name: "ComplaintRegistration",
          query: { identification_type, identification_number },
        });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
  },
};
</script>